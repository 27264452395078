
import { defineComponent, ref, watch } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleCNPJ, campHandleBrPhoneNumber, campHandleEmailVerification, campHandleEmptyFields, campHandleEveryoneIsTrue } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { Modal } from "bootstrap";
import  ModalCropImage from './Components/ModalCropImage.vue'

export type TDaysOfWeek = 'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'|''|null

export const daysOfWeek = [
  { value: '', label: 'Selecione o dia' },
  { value: 'Sunday', label: 'Domingo' },
  { value: 'Monday', label: 'Segunda-feira' },
  { value: 'Tuesday', label: 'Terça-feira' },
  { value: 'Wednesday', label: 'Quarta-feira' },
  { value: 'Thursday', label: 'Quinta-feira' },
  { value: 'Friday', label: 'Sexta-feira' },
  { value: 'Saturday', label: 'Sábado' }
]

interface ICompanyData {
  cnpj: string,
  corporate_name: string,
  fantasy_name: string,
  responsible: string,
  email: string,
  telephone: string,
  type: string,
  min_points_rescue: number,
  moderator_points: number,
  remittance_email: string | null,
  weekday: TDaysOfWeek,
  days_to_expiry?: number | null
  days_to_expiry_stock?: number | null
}

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: ICompanyData
    errorCode: string
  },
  status: number
}

export default defineComponent({
  name: "CompanyPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    ModalCropImage,
  },
  setup() {
    const loaderStore = useLoaderStore();
    const initData:ICompanyData = {
      cnpj: "",
      corporate_name: "",
      fantasy_name: "",
      responsible: "",
      email: "",
      telephone: "",
      type: "",
      min_points_rescue: 1,
      moderator_points: 0,
      remittance_email: null,
      weekday: '',
      days_to_expiry: null,
      days_to_expiry_stock: null
    }
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICompanyData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })
    const modelDaysToExpiry = ref({
      checked: false,
      amountBackup: 30
    })

    const modelDaysToExpiryStock = ref({
      checked: false,
      amountBackup: 30
    })

    const postError = ref();

    const { showTimeAlert } = useAlert()

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cnpj: boolean,
      mail: boolean,
      remittanceEmail: boolean,
      phoneNumber: boolean,
      minPointsRescue: boolean
      moderator_points: boolean
      days_to_expiry: boolean
      days_to_expiry_stock: boolean
     }>({
      cnpj: false,
      mail: false,
      remittanceEmail: false,
      phoneNumber: false,
      minPointsRescue: false,
      moderator_points: false,
      days_to_expiry: false,
      days_to_expiry_stock: false,
    })
    const imgCard = ref({
      imgBack:"" as any,
      imgNameLogo: "",
      imgLogo: "" as any,
      imgNameBack: ""
    });
    const proportion = ref()
    const modatlCroppedLogo = ref(true)

    const auxModal:any = ref(null);
    const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };

    function  base64ToFile(base64String) {
      // Convert base64 to Blob
      const byteCharacters = atob(base64String.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteCharacters.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteCharacters.length; i++) {
        uint8Array[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: 'image/jpeg' });
      
      // Create a File object from the Blob
      return new File([blob], 'image.jpg', { type: 'image/jpeg' });
    }

    const auxModalBack:any = ref(null);
    const openModalBack = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModalBack.value = new Modal(auxElement);
          auxModalBack.value.show();
        };

    function setImageCroped(value) {
      if(!modatlCroppedLogo.value) {
        imgCard.value.imgBack = value.croppedImgBase64
        openModalBack("staticBackdropBack")
      }
      if(modatlCroppedLogo.value) {
        imgCard.value.imgLogo = value.croppedImgBase64
        openModal("staticBackdrop")
      }
    }

    function getImgLogo(event) {
              modatlCroppedLogo.value = true
              proportion.value = 10/10
              const fileInput = event.target as HTMLInputElement;
              const file = fileInput.files?.[0];
              if (file) {
                imgCard.value.imgLogo = file
                imgCard.value.imgNameLogo = file.name
              }
              fileInput.value = "";
            }

    function getImgBack(event) {
      modatlCroppedLogo.value = false
      proportion.value = 10/6
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        imgCard.value.imgBack = file
        imgCard.value.imgNameBack= file.name
      }
      fileInput.value = "";
    }

    /** Validate CNPJ */
    watch(() => postObject.value.data?.cnpj, () => {
      if(postObject.value.data?.cnpj)
        isInvalidField.value.cnpj = !campHandleCNPJ(postObject.value.data.cnpj)
    })

    /** Validate email */
    watch(() => postObject.value.data?.email, () => {
      if(postObject.value.data?.email)
        isInvalidField.value.mail = !campHandleEmailVerification(postObject.value.data.email)
    })

    /** Validate remittance email */
    watch(() => postObject.value.data?.remittance_email, () => {
      if(postObject.value.data?.remittance_email?.length) {
        const emailList = postObject.value.data.remittance_email.split(", ")
        emailList.some(email => isInvalidField.value.remittanceEmail = !campHandleEmailVerification(email))
      } else {
        isInvalidField.value.remittanceEmail = false
        postObject.value.data!.weekday = ''
      }
    })

    /** Validate phone number */
    watch(() => postObject.value.data?.telephone, () => {
      if(postObject.value.data?.telephone)
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(postObject.value.data.telephone)
    })

    /** Validate min points rescue */
    watch(() => postObject.value.data?.min_points_rescue, () => {
      if(!(!!postObject.value.data?.min_points_rescue))
        isInvalidField.value.minPointsRescue = true
      if(postObject.value.data?.min_points_rescue)
        isInvalidField.value.minPointsRescue = (+postObject.value.data.min_points_rescue < 1) ? true : false
    })

    /** Validate moderator points */
    watch(() => postObject.value.data?.moderator_points, () => {
      if(!(postObject.value.data?.moderator_points && postObject.value.data?.moderator_points as any >= 0)) {  
        isInvalidField.value.moderator_points = true
      }
      else {
        isInvalidField.value.moderator_points = false
      }
    })

    /** Validate min days to expiry */
    watch(() => postObject.value.data?.days_to_expiry, () => {
      if(!modelDaysToExpiry.value.checked)
        return
      if(!(!!postObject.value.data?.days_to_expiry))
        isInvalidField.value.days_to_expiry = true
      if(postObject.value.data?.days_to_expiry)
        isInvalidField.value.days_to_expiry = (+postObject.value.data.days_to_expiry < 0) ? true : false
    })

    watch(() => postObject.value.data?.days_to_expiry_stock, () => {
      if(!modelDaysToExpiryStock.value.checked)
        return
      if(!(!!postObject.value.data?.days_to_expiry_stock))
        isInvalidField.value.days_to_expiry_stock = true
      if(postObject.value.data?.days_to_expiry_stock)
        isInvalidField.value.days_to_expiry_stock = (+postObject.value.data.days_to_expiry_stock < 0) ? true : false
    })

    watch(() => modelDaysToExpiry.value.checked, () => {
      if(!postObject.value.data)
        return
      if(!postObject.value.data.days_to_expiry) {
        postObject.value.data.days_to_expiry = +modelDaysToExpiry.value.amountBackup
      } else {
        modelDaysToExpiry.value.amountBackup = +postObject.value.data.days_to_expiry
        postObject.value.data.days_to_expiry = null
      }
    })

    watch(() => modelDaysToExpiryStock.value.checked, () => {
      if(!postObject.value.data)
        return
      if(!postObject.value.data.days_to_expiry_stock) {
        postObject.value.data.days_to_expiry_stock = +modelDaysToExpiryStock.value.amountBackup
      } else {
        modelDaysToExpiryStock.value.amountBackup = +postObject.value.data.days_to_expiry_stock
        postObject.value.data.days_to_expiry_stock = null
      }
    })

    /** Clear warning */
    watch(() => postObject.value, () => {
      isRequiredField.value = false
      isInvalidFieldWarning.value = false
    }, { deep: true })

    async function onSubmitForm() {
      isInvalidFieldWarning.value = false
      isRequiredField.value = false
      const { data } = postObject.value
      const { weekday, remittance_email, moderator_points, days_to_expiry, days_to_expiry_stock, ...rest } = data!
      if(campHandleEmptyFields(rest)) {
        return isRequiredField.value = true
      }
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      if(remittance_email?.length && !(weekday?.length)) {
        return isRequiredField.value = true
      }
      if(modelDaysToExpiry.value.checked && !days_to_expiry) {
        return isRequiredField.value = true
      }
      if(modelDaysToExpiryStock.value.checked && !days_to_expiry_stock) {
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = postObject.value
        if(data) {
          const { telephone, cnpj, weekday, remittance_email, ...rest } = data
          const postData = {
            ...rest,
            telephone: campHandleBrPhoneNumber(telephone, "unformatted"),
            cnpj: campHandleCNPJ(cnpj, "unformatted"),
            front_card_image: imgCard.value.imgLogo,
            back_card_image: imgCard.value.imgBack,
            weekday: remittance_email?.length ? weekday : null,
            remittance_email: remittance_email?.length ? ([ ...new Set(remittance_email.split(", ")) ]).join(", ") : null
          }
          const result: ICompanyResponse = await axios.post(`/api/postCompany`, postData)
          showTimeAlert("Registro criado com sucesso!")
          router.push("/companhias")
          postObject.value.data = { ...initData }
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    function handleRemoveImgLogo() {
      imgCard.value.imgLogo = null
      imgCard.value.imgNameLogo = ""
    }

    function handleRemoveImgBack() {
      imgCard.value.imgBack = null
      imgCard.value.imgNameBack = ""
    }

    return {
      postObject,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      setImageCroped,
      imgCard,
      getImgLogo,
      auxModal,
      openModal,
      proportion,
      getImgBack,
      modatlCroppedLogo,
      openModalBack,
      auxModalBack,
      base64ToFile,
      daysOfWeek,
      modelDaysToExpiry,
      modelDaysToExpiryStock,
      handleRemoveImgLogo,
      handleRemoveImgBack,
    }
  }
})
